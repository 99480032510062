import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
// import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
// // eslint-disable-next-line
// import { ProductContext } from "src/states/productState";

import { Button, Collapse, Container, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { fetchOrderDetails } from "src/api/orders";
import { GlobalContext } from "src/context/GlobalState";
import headerStyle from "src/styles/headerStyle";
import orderStyle from "src/styles/orderStyle";
import tableStyle from "src/styles/tableStyle";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SystemLoader from "src/components/dialogs/Loader/Loader";
import { ORDER_STATUS, ORDER_STATUS_NAME, PAYMENT_STATUS } from "src/constants/constants";
import { isUserSeller } from "src/constants/user_constants";
import { fDate } from "src/utils/formatTime";
import { statusCodes } from "src/utils/statusCodeUtils";

const OrderDetails = () => {
  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));
  let { orderId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("search") || null;

  const {
    dispatch,
    state: { orderState, alertState }
  } = useContext(GlobalContext);

  const [expandedItems, setExpandedItems] = useState([]);

  useEffect(() => {
    fetchOrderDetailsById();

    // eslint-disable-next-line
  }, []);

  const handleExpandClick = (itemId) => {
    setExpandedItems((prev) => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  const fetchOrderDetailsById = async () => {
    try {
      dispatch({ type: "loading", payload: true });
      const response = await fetchOrderDetails(orderId);
      if (response.httpStatusCode === 200) {
        const { customerOrderId, orderDate, items, promoCode, leaderFirstName, leaderLastName, orderStatusId, customer, paymentDetails, deliveryDate, deliveryMethod, totalAmount, paymentStatus } = response.result.data;
        dispatch({
          type: "orderData",
          payload: {
            ...orderState?.orderData,
            customerOrderId,
            orderDate,
            items,
            promoCode,
            leaderFirstName,
            leaderLastName,
            orderStatusId,
            customer,
            deliveryDate,
            paymentDetails,
            deliveryMethod,
            totalAmount,
            paymentStatus
          }
        });
        dispatch({ type: "loading", payload: false });
      } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
        dispatch({ type: "loading", payload: false });
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
        dispatch({ type: "showMessage", payload: true });
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message ?? "Unable to fetch Promo code details");
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  return (
    <>
      <SystemLoader />
      <Grid container spacing={1} sx={{ mt: 0, mx: 2 }} style={headerStyle.headerContainer}>
        <Grid item xs={4}>
          <Typography variant="h4">Orders</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{ mr: 1 }}
            onClick={() => {
              dispatch({ type: "clear" });
              navigate(`/dashboard/orders${searchQuery ? `?search=${searchQuery}` : ""}`, { replace: true });
            }}
          >
            <img alt="add" src="/assets/icons/button/back.svg" />
          </Button>
        </Grid>
      </Grid>
      <Container maxWidth="xxl">
        {/* Order main details section */}
        <Grid container style={{ ...orderStyle.orderContainer, display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={7} style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid>
              <Typography style={orderStyle.title}>Order #{orderState.orderData.customerOrderId}</Typography>
              <Typography style={orderStyle.text}>{fDate(orderState.orderData?.orderDate)}</Typography>
            </Grid>
            <Grid>
              <Typography style={orderStyle.title}>{orderState.orderData?.items ? orderState.orderData?.items.length : 0} Products</Typography>
              <Typography style={orderStyle.text}>Quantity: {orderState.orderData.items.reduce((a, b) => a + b.quantity, 0)} items</Typography>
            </Grid>
            <Grid>
              <Typography style={orderStyle.title}>Promo Code</Typography>
              <Typography style={orderStyle.text}>{orderState.orderData?.promoCode ?? "NA"}</Typography>
            </Grid>
            <Grid>
              <Typography style={orderStyle.title}>CL Name</Typography>
              <Typography style={orderStyle.text}>
                {orderState.orderData.leaderFirstName} {orderState.orderData.leaderLastName}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography style={{ ...orderStyle.buttonStatus, backgroundColor: ORDER_STATUS[orderState.orderData?.orderStatusId] }}>{ORDER_STATUS_NAME[orderState.orderData?.orderStatusId]}</Typography>
          </Grid>
        </Grid>
        {/* Customer and payment details section */}
        <Grid container sx={{ my: 2, height: "14em" }} style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={5.9} style={{ ...orderStyle.orderContainer, display: "flex" }}>
            <Grid item xs={5}>
              <Typography style={orderStyle.title}>Customer Details</Typography>
              <Grid sx={{ my: 2 }}>
                <Typography style={{ ...orderStyle.subTitle, marginBottom: "8px" }}>Customer Name</Typography>
                {isSeller && orderState.orderData?.customer?.customerType === "Brand customer" ? (
                  <Link to={`/dashboard/crm/details/${orderState.orderData?.customer?.customerId}`}>
                    <Typography style={orderStyle.underlineText}>{`${orderState.orderData?.customer?.customerFirstName ?? "-"} ${orderState.orderData.customer?.customerLastName ?? "-"}`}</Typography>
                  </Link>
                ) : isSeller && orderState.orderData?.customer?.customerType !== "Brand customer" ? (
                  <Typography>{`${orderState.orderData?.customer?.customerFirstName ?? "-"} ${orderState.orderData.customer?.customerLastName ?? "-"}`}</Typography>
                ) : (
                  <Link to={`/dashboard/crm/details/${orderState.orderData?.customer?.customerId}`}>
                    <Typography style={orderStyle.underlineText}>{`${orderState.orderData?.customer?.customerFirstName ?? "-"} ${orderState.orderData.customer?.customerLastName ?? "-"}`}</Typography>
                  </Link>
                )}
              </Grid>
              {orderState.orderData?.customer?.customerAddress?.length > 0 && (
                <Grid spacing={1} sx={{ my: 2 }}>
                  <Typography style={{ ...orderStyle.subTitle, marginBottom: "8px" }}>Address</Typography>
                  <Typography style={orderStyle.text}>{`${orderState.orderData?.customer?.customerAddress?.landMark ?? ""} ${orderState.orderData?.customer?.customerAddress?.street ?? ""}, ${orderState.orderData.customer?.customerAddress?.city ?? ""}, ${orderState.orderData.customer?.customerAddress?.country ?? ""}`}</Typography>
                </Grid>
              )}

              {orderState.orderData?.customer?.customerPhoneNumber && (
                <Grid spacing={1} sx={{ my: 2 }}>
                  <Typography style={{ ...orderStyle.subTitle, marginBottom: "8px" }}>Phone</Typography>
                  <Typography style={orderStyle.text}>{orderState.orderData?.customer?.customerPhoneNumber}</Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={5}>
              <Typography style={orderStyle.title}>Delivery Details</Typography>
              <Grid spacing={1} sx={{ my: 2 }}>
                <Typography style={{ ...orderStyle.subTitle, marginBottom: "8px" }}>Delivery Date</Typography>
                <Typography style={orderStyle.text}>{fDate(orderState.orderData.deliveryDate)}</Typography>
              </Grid>
              <Grid spacing={1} sx={{ my: 2 }}>
                <Typography style={{ ...orderStyle.subTitle, marginBottom: "8px" }}>Type</Typography>
                {orderState.orderData?.paymentDetails.map((data) => (
                  <Typography style={orderStyle.text}>{data.paymentMode}</Typography>
                ))}
              </Grid>
              <Grid spacing={1} sx={{ my: 2 }}>
                <Typography style={{ ...orderStyle.subTitle, marginBottom: "8px" }}>Mode</Typography>
                <Typography style={orderStyle.text}>{orderState.orderData.deliveryMethod}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5.9} style={{ ...orderStyle.orderContainer }}>
            <Grid style={{ display: "flex" }}>
              <Grid item xs={6}>
                <Typography style={orderStyle.title}>Payment Details</Typography>
              </Grid>
              <Grid item xs={6} style={{ float: "right" }}>
                <Typography style={{ ...orderStyle.buttonStatus, backgroundColor: PAYMENT_STATUS[orderState.orderData?.paymentStatus ?? "PENDING"] }}>{orderState.orderData?.paymentStatus ?? "###"}</Typography>
              </Grid>
            </Grid>
            <Grid style={{ display: "flex" }}>
              <Typography style={{ ...orderStyle.title, fontWeight: "400" }}>Total Amount : {orderState.orderData?.totalAmount ? orderState.orderData?.totalAmount.toLocaleString() : "-"}</Typography>
            </Grid>
            <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "1em", marginBottom: "1em" }}>
              <Grid xs={6}>
                <Typography style={{ ...orderStyle.subTitle, marginBottom: "4px" }}>Transaction ID</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography style={{ ...orderStyle.subTitle, marginBottom: "4px" }}>Amount</Typography>
              </Grid>
              <Grid xs={3}>
                <Typography style={{ ...orderStyle.subTitle, marginBottom: "4px" }}>Payment Mode</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography style={{ ...orderStyle.subTitle, marginBottom: "4px" }}>M-Pesa Code</Typography>
              </Grid>
            </Grid>

            {orderState.orderData?.paymentDetails &&
              orderState.orderData?.paymentDetails.map((data) => (
                <>
                  <Grid style={{ display: "flex", justifyContent: "space-between", paddingTop: "8px" }}>
                    <Grid xs={6}>
                      <Typography style={orderStyle.subText}>{data.transactionId}</Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography style={orderStyle.subText}>{data.amount}</Typography>
                    </Grid>
                    <Grid xs={3}>
                      <Typography style={orderStyle.subText}>{data.paymentMode}</Typography>
                    </Grid>
                    <Grid xs={2}>
                      <Typography style={orderStyle.subText}>{data?.mPesaCode ?? "-"}</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 1 }} />
                </>
              ))}
          </Grid>
        </Grid>

        {/* Order iteme list section */}
        <Grid sx={{ my: 4 }} container style={{ ...orderStyle.orderContainer }}>
          <Typography style={{ ...orderStyle.text, fontWeight: "600" }}>Order Details</Typography>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="Items bought">
              <TableBody>
                {orderState.orderData?.items.map((item) => {
                  const isExpanded = expandedItems[item.itemId];
                  return (
                    <React.Fragment key={item.itemId}>
                      <TableRow>
                        <TableCell align="left" style={{ ...tableStyle.cellStyle, position: "relative" }}>
                          {item.isDigitalProduct ? (
                            <IconButton style={{ position: "absolute", left: -8, top: 10 }} onClick={() => handleExpandClick(item.itemId)}>
                              {isExpanded ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          ) : null}
                          <p style={{ paddingLeft: 30 }}>{`${item.itemName} ${item.brandName} ${item.quantity} x ${item.productVariant}`}</p>
                        </TableCell>
                        <TableCell style={tableStyle.cellStyle} align="left">
                          <p>{item.categoryName}</p>
                        </TableCell>
                        <TableCell style={tableStyle.cellStyle} align="right">
                          <p>{`000${item.itemId}`}</p>
                        </TableCell>
                        <TableCell style={tableStyle.cellStyle} align="right">
                          <p>{item.quantity}</p>
                        </TableCell>
                        <TableCell style={tableStyle.cellStyle} align="right">
                          <p>{`Ksh ${item.totalAmount}`}</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            {item.isDigitalProduct &&
                              item.ticketDetails.map((ticket) => (
                                <div key={ticket.id} style={{ display: "flex", justifyContent: "space-between", paddingLeft: "2em" }}>
                                  <p>{ticket.itemCode}</p>
                                  <p>Ksh {ticket.itemPrice}</p>
                                </div>
                              ))}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </>
  );
};

export default OrderDetails;
