import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { GlobalContext } from "src/context/GlobalState";
import FirebaseApp from "src/firebase/firebase";

import { useDropzone } from "react-dropzone";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { createOrUpdateCategory, createOrUpdateSubCategory } from "src/api/products";
import { addProductButton, addVariantButton } from "src/styles/styles";
import { CATEGORY_TYPE } from "src/constants/constants";
import { Close } from "@mui/icons-material";
import { statusCodes } from "src/utils/statusCodeUtils";

export default function CategoryDialog() {
  const {
    dispatch,
    state: { categoryState, globalState, alertState }
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const storage = getStorage(FirebaseApp);

  // Image upload
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((data) => {
        handleUploadImage(data);
      });
    }
  });

  // Handle upload image
  const handleUploadImage = (image) => {
    const defaultFileSize = process.env.REACT_APP_FILESIZE_LIMIT;
    if (image?.size > defaultFileSize) alert("Please upload product image less than 150kb?");
    const Reference = ref(storage, `SKU/Category/CategoryImages/${Date.now()}-${image.name}`);
    uploadBytes(Reference, image).then((snapshot) => {
      getDownloadURL(Reference).then((url) => {
        dispatch({ type: "categoryRecord", payload: { ...categoryState.categoryRecord, imageUrl: url } });
      });
    });
  };

  const handleCategorySubmit = async () => {
    try {
      setLoading(true);
      let categoryObj = categoryState?.categoryRecord;
      if (categoryState?.categoryType === CATEGORY_TYPE.primaryCategory) {
        let { parentId, ...newObj } = categoryState?.categoryRecord;
        categoryObj = newObj;
      }

      let response;
      if (!categoryObj.imageUrl) {
        setLoading(false);
        alert("Please provide category image");
        return;
      }
      if (!categoryObj.name) {
        setLoading(false);
        alert("Please provide category name");
        return;
      }
      if (categoryState?.categoryType === CATEGORY_TYPE.primaryCategory) {
        response = await createOrUpdateCategory(categoryObj);
      } else {
        response = await createOrUpdateSubCategory(categoryObj);
      }
      setLoading(false);
      if (response.httpStatusCode === 200) {
        alert(response?.message);
        dispatch({ type: "categoryClear" });
        dispatch({ type: "reload", payload: !globalState.reload });
      } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
        dispatch({ type: "showMessage", payload: true });
      } else {
        alert(response?.message ?? "Error during creating/updating !");
      }
    } catch (error) {
      setLoading(false);
      alert(error?.message);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open={categoryState.showCategoryDialog} onClose={() => dispatch({ type: "categoryClear" })}>
      <Grid sx={{ backgroundColor: "#F2F2F2", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <DialogTitle>
          {categoryState?.categoryRecord?.categoryId && categoryState?.categoryType === CATEGORY_TYPE.primaryCategory
            ? "Edit Category"
            : !categoryState?.categoryRecord?.categoryId && categoryState?.categoryType === CATEGORY_TYPE.primaryCategory
              ? "Create Category"
              : !categoryState?.categoryRecord?.categoryId && categoryState?.categoryRecord?.parentId && categoryState?.categoryType === CATEGORY_TYPE.subCategory
                ? "Add Sub Category"
                : categoryState?.categoryRecord?.categoryId && categoryState?.categoryRecord?.parentId && categoryState?.categoryType === CATEGORY_TYPE.subCategory
                  ? "Edit Sub Category"
                  : ""}
        </DialogTitle>
        <Close onClick={() => dispatch({ type: "categoryClear" })} sx={{ mx: 1, cursor: "pointer" }} />
      </Grid>
      <DialogContent>
        {!categoryState?.categoryRecord?.categoryId && categoryState?.categoryRecord?.parentId && categoryState?.categoryType === CATEGORY_TYPE.subCategory && (
          <Grid sx={{ border: "1px solid #D2D2D2", borderRadius: "8px", mb: 2, p: 1, display: "flex", gap: 2, alignItems: "center" }}>
            <img height={40} width={40} src={categoryState?.selectedParentRecord?.imageUrl} alt="category_image" />
            <Typography>{categoryState?.selectedParentRecord?.name}</Typography>
          </Grid>
        )}
        <Grid container>
          <Grid item xs={4}>
            <Box {...getRootProps()} style={{ height: "205px", width: "200px", border: "1px dotted #D2D2D2" }}>
              <input {...getInputProps()} />
              <img src={categoryState.categoryRecord?.imageUrl ?? "/assets/NoImage.png"} style={{ height: "180px", width: "200px" }} alt="Catrgory" />
              <p style={{ color: "white", textAlign: "center", marginTop: "auto", fontWeight: "700", background: "#232F3E" }}>Change</p>
            </Box>
          </Grid>
          <Grid item xs={7} alignItems="center" justifyContent="center">
            <TextField sx={{ marginTop: 0 }} fullWidth label="Category" aria-label="Category" margin="normal" variant="standard" value={categoryState?.categoryRecord?.name ?? null} onChange={(e) => dispatch({ type: "categoryRecord", payload: { ...categoryState?.categoryRecord, name: e.target.value } })} InputLabelProps={{ shrink: true }} />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" style={addVariantButton} onClick={() => dispatch({ type: "categoryClear" })}>
          Cancel
        </Button>
        <Button variant="outlined" style={{ ...addProductButton, background: !categoryState.categoryRecord?.imageUrl || !categoryState.categoryRecord?.name ? "#D2D2D2" : "#FE7C19" }} onClick={() => handleCategorySubmit()} disabled={!categoryState.categoryRecord?.imageUrl || !categoryState.categoryRecord?.name}>
          {loading ? <CircularProgress size={20} sx={{ mx: 1, color: "#FFF" }} /> : null} {categoryState?.categoryRecord.categoryId ? "Edit" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
