import { useContext, useEffect, useState } from "react";
// import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
// // eslint-disable-next-line

import { Button, Container, Divider, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchUserDetails, updateUserToCL } from "src/api/users";
import AddressPopUp from "src/components/dialogs/AddressPopUp";
import { GlobalContext } from "src/context/GlobalState";
import { pTable } from "src/styles/styles";
import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import { fDate } from "src/utils/formatTime";

import SystemLoader from "src/components/dialogs/Loader/Loader";
import { isUserSeller } from "src/constants/user_constants";
import headerStyle from "src/styles/headerStyle";
import orderStyle from "src/styles/orderStyle";
import { statusCodes } from "src/utils/statusCodeUtils";
import { transactionTypeSchema } from "src/utils/utils";

const CRMDetails = () => {
  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("category");
  const {
    dispatch,
    state: { crmState, alertState }
  } = useContext(GlobalContext);

  let { customerId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("search") || null;
  const [reload, setReload] = useState(false);

  useEffect(() => {
    fetchUserDetailsByUserId();
  }, [reload]);

  const fetchUserDetailsByUserId = async () => {
    try {
      if (!customerId) {
        navigate("/dashboard/crm");
        return;
      }
      dispatch({ type: "loading", payload: true });
      const response = await fetchUserDetails(customerId);
      if (response.httpStatusCode === 200) {
        const { userId, userFistName, userLastName, userRegiseredNumber, address, clFirstName, clLastName, signUpDate, lastLoginDate, role, walletBalance, payouts, topUps, bonus, transactions, nextJipangeAmount, nextJipangeDate, orderAmount } = response.result.data?.responseObj;
        dispatch({
          type: "crmData",
          payload: {
            ...crmState?.crmData,
            userId,
            userFistName,
            userLastName,
            userRegiseredNumber,
            address,
            clFirstName,
            clLastName,
            signUpDate,
            role,
            lastLoginDate,
            walletBalance,
            payouts,
            topUps,
            bonus,
            transactions,
            nextJipangeAmount,
            nextJipangeDate,
            orderAmount
          }
        });
        dispatch({ type: "loading", payload: false });
      } else if (response.httpStatusCode === statusCodes.unAuthorizedAccess) {
        dispatch({ type: "loading", payload: false });
        dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "UnAuthorized access", heading: response?.message, body: response?.displayMessage, type: "warning" } });
        dispatch({ type: "showMessage", payload: true });
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message ?? "Unable to fetch user details");
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  const headCells = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: true,
      label: "Order ID"
    },
    {
      id: "transID",
      numeric: false,
      disablePadding: true,
      label: "Transaction ID"
    },
    {
      id: "mPesaCode",
      numeric: false,
      disablePadding: true,
      label: "M-Pesa Code"
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: true,
      label: "Total Amount"
    },
    {
      id: "paymentDate",
      numeric: false,
      disablePadding: true,
      label: "Payment Date"
    },
    {
      id: "mode",
      numeric: false,
      disablePadding: true,
      label: "Mode"
    },
    {
      id: "paymentNumber",
      numeric: false,
      disablePadding: true,
      label: "Payment Number"
    },
    {
      id: "transType",
      numeric: false,
      disablePadding: true,
      label: "Transaction Type"
    },
    {
      id: "status ",
      numeric: false,
      disablePadding: true,
      label: "Order Status"
    },
    {
      id: "promoCode",
      numeric: false,
      disablePadding: true,
      label: "Promo Code"
    }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeActivity = async (e) => {
    try {
      const body = {
        userId: parseInt(customerId),
        roleId: parseInt(e.target.value),
        enableRole: true
      };

      const response = await updateUserToCL(body);
      if (response.httpStatusCode === 200) {
        const response2 = await fetchUserDetails(customerId);
        if (response2.httpStatusCode === 200) {
          dispatch({ type: "crmData", payload: response2.result.data.responseObj });
          dispatch({ type: "crmDetails", payload: true });
          alert(response.message);
        } else {
          alert(response.message);
        }
      } else {
        alert(response.message);
      }
    } catch (error) {
      alert(error?.message);
    }
  };

  return (
    <>
      <SystemLoader />
      <Grid container spacing={1} sx={{ mt: 0, mx: 2 }} style={headerStyle.headerContainer}>
        <Grid item xs={4}>
          <Typography variant="h4">CRM</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{ mr: 1 }}
            onClick={() => {
              navigate(`/dashboard/crm${searchQuery ? `?search=${searchQuery}` : ""}`, { replace: true });
            }}
          >
            <img alt="add" src="/assets/icons/button/back.svg" />
          </Button>
        </Grid>
      </Grid>
      <Container maxWidth="xxl">
        {crmState?.crmData?.address.length > 0 ? <AddressPopUp /> : null}
        <Grid container sx={{ my: 2, height: "auto" }} style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={5.9} style={{ ...orderStyle.orderContainer }}>
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
              <Grid>
                <Typography style={{ ...orderStyle.text, fontSize: "18px", fontWeight: "600" }}>
                  {crmState?.crmData?.userFistName ?? "-"} {crmState?.crmData?.userLastName ?? "-"}
                </Typography>
                <Typography
                  onClick={() => {
                    crmState?.crmData?.address.length === 0 ? alert("No address registered") : dispatch({ type: "displayAddress", payload: true });
                  }}
                  style={{ ...orderStyle.underlineText, fontWeight: "500", cursor: "pointer" }}
                >
                  {crmState?.crmData?.address[0]?.landMark ?? ""} {crmState?.crmData?.address[0]?.street ?? ""} {crmState?.crmData?.address[0]?.city ?? ""} {crmState?.crmData?.address[0]?.country ?? ""}
                </Typography>
              </Grid>
              <Grid></Grid>
            </Grid>
            <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "1em" }}>
              <Grid item xs={5.5}>
                <Typography style={{ ...orderStyle.text, fontColor: "#8C8C8C" }}>Registered Number</Typography>
                <Typography style={{ ...orderStyle.text, fontColor: "#8C8C8C" }}>{crmState?.crmData?.userRegiseredNumber ?? "-"}</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ ...orderStyle.text, fontColor: "#8C8C8C" }}>Keeper Name</Typography>
                <Typography style={{ ...orderStyle.text, fontColor: "#8C8C8C" }}>
                  {crmState?.crmData?.clFirstName ?? ""} {crmState?.crmData?.clLastName ?? ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "1em" }}>
              <Grid item xs={5.5}>
                <Typography style={{ ...orderStyle.text, fontColor: "#8C8C8C" }}>Sign up Date</Typography>
                <Typography style={{ ...orderStyle.text, fontColor: "#8C8C8C" }}>{crmState?.crmData?.signUpDate ? fDate(crmState?.crmData?.signUpDate) : "-"}</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ ...orderStyle.text, fontColor: "#8C8C8C" }}>Last Login Date</Typography>
                <Typography style={{ ...orderStyle.text, fontColor: "#8C8C8C" }}>{crmState?.crmData?.lastLoginDate ? fDate(crmState?.crmData?.lastLoginDate) : "-"}</Typography>
              </Grid>
            </Grid>
            {!isSeller && (
              <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "3em" }}>
                <Grid item xs={5.5}>
                  <Typography style={{ ...orderStyle.text, fontColor: "#8C8C8C", fontWeight: "600" }}>Role Assignment</Typography>
                </Grid>
                <Grid item xs={5.5} style={{ display: "flex", justifyContent: "space-around" }}>
                  <FormControl>
                    <RadioGroup row value={crmState?.crmData?.role} aria-labelledby="role" name="role" onChange={(e) => handleChangeActivity(e)}>
                      <FormControlLabel dis value={2} control={<Radio />} label="Keeper" />
                      <FormControlLabel value={3} disabled control={<Radio />} label="Customer" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Grid>
          {!isSeller && (
            <Grid item xs={5.9} style={{ ...orderStyle.orderContainer }}>
              <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                <Grid>
                  <Typography style={{ ...orderStyle.text, fontSize: "16px", fontWeight: "600" }}>Customer stats</Typography>
                </Grid>
                <Grid></Grid>
              </Grid>
              <Grid style={{ display: "flex", marginTop: "1em" }}>
                <Grid item xs={4}>
                  <Typography style={{ ...orderStyle.text, color: "#8C8C8C" }}>Current Wallet Balance</Typography>
                  <Typography style={{ ...orderStyle.text }}>{crmState?.crmData?.walletBalance ? crmState?.crmData?.walletBalance.toLocaleString() : "-"}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ ...orderStyle.text, color: "#8C8C8C" }}>OrderAmount Till Date</Typography>
                  <Typography style={{ ...orderStyle.text }}> {crmState?.crmData?.orderAmount.toLocaleString() ?? 0}</Typography>
                </Grid>
              </Grid>
              <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "1em" }}>
                <Grid item xs={4}>
                  <Typography style={{ ...orderStyle.text, color: "#8C8C8C" }}>Top Up's Till Date</Typography>
                  <Typography style={{ ...orderStyle.text }}>{crmState?.crmData?.topUps.toLocaleString()}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ ...orderStyle.text, color: "#8C8C8C" }}>Bonuses Till Date</Typography>
                  <Typography style={{ ...orderStyle.text }}> {crmState?.crmData?.bonus.toLocaleString() ?? 0}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ ...orderStyle.text, color: "#8C8C8C" }}>Payouts Till Date</Typography>
                  <Typography style={{ ...orderStyle.text }}> {crmState?.crmData?.payouts ? crmState?.crmData?.payouts.toLocaleString() : ""}</Typography>
                </Grid>
              </Grid>
              <Grid style={{ display: "flex", marginTop: "1em" }}>
                <Grid item xs={4}>
                  <Typography style={{ ...orderStyle.text, color: "#8C8C8C" }}>Next Jipange Date</Typography>
                  <Typography style={{ ...orderStyle.text }}>{crmState?.crmData?.nextJipangeDate ? fDate(crmState?.crmData?.nextJipangeDate) : "-"}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ ...orderStyle.text, color: "#8C8C8C" }}>Next Jipange Amount</Typography>
                  <Typography style={{ ...orderStyle.text }}> {crmState?.crmData?.nextJipangeAmount}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid>
          <Typography style={{ ...orderStyle.text, fontSize: "16px", fontWeight: "600" }}>Transaction History</Typography>
          <TableContainer component={Paper} style={{ height: "450px" }}>
            <Table stickyHeader size="small" aria-label="Transaction History">
              <TableHead>
                <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                  <EnhancedTableHead
                    headCells={headCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    // rowCount={crmState?.crmData.items.length}
                    style={{ whiteSpace: "nowrap" }}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {crmState?.crmData?.transactions.map((data) => (
                  <TableRow key={data.itemId}>
                    <TableCell>
                      <p style={pTable}>{data.customerOrderId}</p>
                    </TableCell>
                    <TableCell align="left">
                      <p style={pTable}>{data.tranReferenceNumber}</p>
                    </TableCell>
                    <TableCell align="left">
                      <p style={pTable}>{data.mPesaCode}</p>
                    </TableCell>
                    <TableCell align="left">
                      <p style={pTable}>Ksh {data.amount}</p>
                    </TableCell>
                    <TableCell align="left">
                      <p style={pTable}>{fDate(data.paymentDate)}</p>
                    </TableCell>
                    <TableCell align="left">{data.mode}</TableCell>
                    <TableCell align="left">
                      <p style={pTable}>{data.tranPhoneNumber}</p>
                    </TableCell>
                    <TableCell align="left">
                      <Button sx={{ py: 1, alignItems: "center" }} style={{ ...pTable, width: "60%", color: "#FFF", backgroundColor: transactionTypeSchema(data.transactionTypeId).colorScheme }}>
                        {transactionTypeSchema(data.transactionTypeId).name}
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      <p style={pTable}>{data.status}</p>
                    </TableCell>
                    <TableCell align="left">
                      <p style={pTable}>{data?.promoCode ?? "NA"}</p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </>
  );
};

export default CRMDetails;
