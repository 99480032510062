import { Radio } from "@mui/material";
import { useContext } from "react";
// eslint-disable-next-line
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import { urlType } from "src/constants/constants";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";
import FirebaseApp from "src/firebase/firebase";
import { imageContainer } from "src/styles/styles";
import { urlIsVideoOrImageUpload } from "src/utils/utils";
import { RenderMedia } from "../media/RenderMedia";

const CarrouselProductUpload = (props) => {
  const storage = getStorage(FirebaseApp);
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((data, index) => {
        handleUploadGalleryImage(data, index);
      });
    }
  });

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  const handleUploadGalleryImage = async (media, index) => {
    try {
      dispatch({ type: "productCarouselUpload", payload: true });
      if (!urlIsVideoOrImageUpload(media)) {
        dispatch({ type: "productCarouselUpload", payload: false });
        alert("Please upload correct file format");
        return;
      }
      const defaultFileSize = urlIsVideoOrImageUpload(media) === urlType.image ? process.env.REACT_APP_FILESIZE_LIMIT : process.env.REACT_APP_FILESIZE_VIDEO_LIMIT;
      if (media?.size > defaultFileSize) {
        dispatch({ type: "productCarouselUpload", payload: false });
        alert(urlIsVideoOrImageUpload(media) === urlType.image ? "Please upload product image less than 150kb?" : "Please upload product video less than 5Mb?");
      } else {
        const Reference = ref(storage, `SKU/Products/${urlIsVideoOrImageUpload(media) === urlType.image ? "ProductImages" : "ProductVideos"}/${Date.now()}-${media.name}`);
        await uploadBytes(Reference, media);
        const url = await getDownloadURL(Reference);
        const updatedImageUrls = [...(productState.productRecord.productImageUrls || [])];
        if (updatedImageUrls[props.index]) {
          updatedImageUrls[props.index].url = url;
        } else {
          updatedImageUrls.push({ url, isPrimary: false });
        }
        dispatch({ type: "productRecord", payload: { ...productState?.productRecord, productImageUrls: updatedImageUrls } });
      }
    } catch (error) {
      dispatch({ type: "productCarouselUpload", payload: false });
      alert(error?.message ?? "An error occured during file upload");
    } finally {
      dispatch({ type: "productCarouselUpload", payload: false });
    }
  };

  const handleRadioClick = (e) => {
    let updatedImageUrls = [...productState.productRecord.productImageUrls];
    const primaryImage = updatedImageUrls.find((image) => image.isPrimary);
    if (primaryImage) {
      primaryImage.isPrimary = false;
    }
    updatedImageUrls[props.index].isPrimary = !updatedImageUrls[props.index].isPrimary;
    dispatch({ type: "productRecord", payload: { ...productState?.productRecord, productImageUrls: updatedImageUrls } });
  };

  const handleDeleteClick = (e) => {
    let updatedImageUrls = [...productState.productRecord.productImageUrls];
    updatedImageUrls.splice(props.index, 1);
    dispatch({ type: "productRecord", payload: { ...productState?.productRecord, productImageUrls: updatedImageUrls } });
  };

  return (
    <div
      style={{
        boxSizing: "border-box",
        marginLeft: "auto",
        marginRight: "auto",
        width: "95%",
        height: "210px",
        borderWidth: "1px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#BFBFBF",
        borderRadius: "8px 8px 8px 8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        position: "relative"
      }}
    >
      <div {...getRootProps()} style={imageContainer}>
        <RenderMedia url={productState.productRecord.productImageUrls?.[props.index] === undefined ? null : productState.productRecord.productImageUrls?.[props.index].url || productState.productRecord.productImageUrls?.[props.index].imageUrl} />
        <input disabled={isSeller} {...getInputProps()} />
      </div>
      {productState.productRecord.productImageUrls?.[props.index] === undefined ? (
        <></>
      ) : (
        <>
          {!isSeller && <Radio name="selectedImage" value={props.index} style={{ position: "absolute" }} checked={productState.productRecord.productImageUrls?.[props.index].isPrimary} onChange={(e) => handleRadioClick(e)} />}

          {/* <span style={{ flexGrow: 1 }} /> */}
          {!isSeller && (
            <img
              src="/assets/icons/button/delete.svg"
              style={{
                position: "absolute",
                right: "10px",
                top: "10px"
              }}
              onClick={() => {
                handleDeleteClick();
              }}
            />
          )}
        </>
      )}
      {/* <p style={{ color: "white", textAlign: "center", marginTop: "auto", fontWeight: "700" }}>Change</p> */}
    </div>
  );
};

export default CarrouselProductUpload;
