import TableCell from "@mui/material/TableCell";
import { useContext, useEffect, useState } from "react";
import { CSVDownload } from "react-csv";
import { Helmet } from "react-helmet-async";
// @mui
import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
// components

import { addProductButton, addVariantButton, filterButton, pTable, statusButtons } from "../../styles/styles";

// eslint-disable-next-line
import { Search } from "@mui/icons-material";
import { debounce } from "lodash";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { downloadJipangeOrderData, downloadOrderData, fetchTableData } from "src/api/orders";
import JipangeOrderFilter from "src/components/dialogs/JipangeOrderFilter/JipangeOrderFilter";
import OrderFilter from "src/components/dialogs/OrderFilter";
import { colors } from "src/constants/constants";
import { jipangeCSV, orderCSV } from "src/constants/csv";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";
import headerStyle from "src/styles/headerStyle";
import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import { getComparator, stableSort } from "src/utils/sortTables";
import { useQuery } from "src/utils/utils";
import { dateToUTC, fDate } from "../../utils/formatTime";
import OrderDetails from "./OrderDetails";
// mock
// import PRODUCTS from '../_mock/products';

// ----------------------------------------------------------------------

export default function OrderPage() {
  //Handle search persist
  const searchQuery = useQuery().get("search") || null;
  const navigate = useNavigate();

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  const [exportt, setExport] = useState(false);
  const [orderTable, setOrderTable] = useState([]);
  const [jipangeExport, setJipangeExport] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("category");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [exportData, setExportData] = useState([]);
  const [jipangeExportData, setJipangeExportData] = useState([]);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [searchText, setSearchText] = useState(searchQuery);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const {
    dispatch,
    state: { orderState }
  } = useContext(GlobalContext);

  useEffect(() => {
    if (searchText && searchText.trim().length >= 3 && searchText !== "null") {
      setSearchParams({ search: searchText });
    } else {
      setSearchText(null);
      setSearchParams({});
    }
  }, [searchText, setSearchParams]);

  useEffect(() => {
    try {
      if (!orderState.orderFilter) {
        if (searchText && searchText.trim().length > 0 && searchText.trim().length < 3) return;
        updateTableData(pageSize, 0);
      }
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
    // eslint-disable-next-line
  }, [orderState.orderFilter, searchText]);

  const updateTableData = async (pageSize, page) => {
    const filter = {
      customerIds: orderState.customerId,
      leaderIds: orderState.leaderId,
      paymentStatuses: orderState.paymentStatuses,
      orderStatuses: orderState.orderStatuses,
      platform: orderState.platform,
      fromDate: orderState.fromDate === null ? null : dateToUTC(orderState.fromDate),
      toDate: orderState.toDate === null ? null : dateToUTC(orderState.toDate),
      deliveryMethods: orderState.deliveryMethods,
      purchaseTypes: orderState.purchaseTypes
    };
    setLoading(true);
    const body = {
      pageNumber: page,
      pageSize,
      filter,
      searchOrderId: searchText
    };
    const response = await fetchTableData(body);

    if (response.httpStatusCode === 200) {
      const { orders, pageNumber, pageSize, totalCount } = response.result.data;
      const array = [];
      orders.forEach((data) => {
        const { orderId, customerOrderId, orderAmount, purchaseType, createdAt, deliveryDate, leader, orderStatus, orderStatusId, customer, paymentStatus, deliveryMode, paymentMode, promoCode, platform, customerType, deliveryBatchType } = data;
        const body = {
          orderId,
          customerOrderId,
          orderAmount,
          purchaseType: purchaseType.name,
          createdAt: fDate(createdAt),
          deliveryDate: fDate(deliveryDate),
          clid: leader === null ? "-" : leader.leaderId,
          clName: leader === null ? "-" : leader.firstName === null ? null : `${leader.firstName} ${leader.lastName}`,
          orderStatus: orderStatusId === 1 ? `orderPending` : orderStatusId === 2 ? `orderPlaced` : orderStatusId === 3 ? `cancelled` : orderStatusId === 4 ? `inTransit` : orderStatusId === 5 ? "readyForPickUp" : orderStatusId === 6 ? `delivered` : orderStatus,
          deliveryBatchType: deliveryBatchType ?? "-",
          customerID: customer.customerId,
          customerName: customer.firstName === null ? null : `${customer.firstName} ${customer.lastName}`,
          customerType,
          paymentStatus: paymentStatus === "FAILURE" ? "failed" : paymentStatus === "PENDING" ? "pending" : paymentStatus === "SUCCESS" ? "paid" : null,
          deliveryMode,
          paymentMode,
          promoCode,
          platform
        };
        array.push(body);
      });

      setOrderTable(array);
      setPage(parseInt(pageNumber));
      setPageSize(parseInt(pageSize));
      setTotalProductsCount(totalCount);

      setSearchParams({ ...searchParams, search: searchText });

      setLoading(false);
    } else if (!orderState.orderFilter) {
      dispatch({ type: "ordersList", payload: [] });
      setLoading(false);
      alert(response.message);
    }
  };

  const headCells = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: true,
      label: "Order No."
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: true,
      label: "Order Date"
    },
    {
      id: "purchaseType",
      numeric: false,
      disablePadding: true,
      label: "Type"
    },
    {
      id: "deliveryMode",
      numeric: false,
      disablePadding: true,
      label: "Mode"
    },
    {
      id: "deliveryDate",
      numeric: false,
      disablePadding: true,
      label: "Delivery Date"
    },
    {
      id: "clid",
      numeric: false,
      disablePadding: true,
      label: "CL Name"
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: true,
      label: "Customer"
    },
    {
      id: "customerType",
      numeric: false,
      disablePadding: true,
      label: "Customer Type"
    },
    {
      id: "paymentStatus",
      numeric: false,
      disablePadding: true,
      label: "Payment Status"
    },
    {
      id: "orderAmount",
      numeric: false,
      disablePadding: true,
      label: "Amount"
    },
    {
      id: "orderStatus",
      numeric: false,
      disablePadding: true,
      label: "Order status"
    },
    {
      id: "deliveryBatchType",
      numeric: false,
      disablePadding: true,
      label: "Batch Type"
    },
    {
      id: "platform",
      numeric: false,
      disablePadding: true,
      label: "Platform"
    },
    {
      id: "promoCode",
      numeric: false,
      disablePadding: true,
      label: "Promo Code"
    }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getFromDate = () => {
    const date = new Date();
    const startDate = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + "T00:00:00";
    return startDate;
  };

  const getDefaultEndDate = () => {
    const date = new Date();
    const today = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + "T23:59:59";

    return today;
  };

  const handleExport = async (confirmed) => {
    try {
      const exportPageSize = parseInt(process.env.REACT_APP_EXPORT_PAGESIZE);
      let currentPageSize = parseInt(process.env.REACT_APP_EXPORT_PAGESIZE);

      dispatch({ type: "orderConfirmed", payload: confirmed });
      dispatch({ type: "orderLoading", payload: true });

      if (confirmed) {
        let array = [];
        for (let i = 0; currentPageSize !== 0; i++) {
          const body2 = {
            pageNumber: i,
            pageSize: exportPageSize,
            filter: {
              customerIds: orderState.customerId,
              leaderIds: orderState.leaderId,
              paymentStatuses: orderState.paymentStatuses,
              orderStatuses: orderState.orderStatuses,
              deliveryMethods: orderState.deliveryMethods,
              purchaseTypes: orderState.purchaseTypes,
              platform: orderState.platform,
              fromDate: orderState.fromDate === null ? dateToUTC(getFromDate()) : dateToUTC(orderState.fromDate),
              toDate: orderState.toDate === null ? dateToUTC(getDefaultEndDate()) : dateToUTC(orderState.toDate)
            }
          };
          const responseExport = await downloadOrderData(body2);
          if (responseExport.httpStatusCode !== 200) {
            dispatch({ type: "orderConfirmed", payload: false });
            dispatch({ type: "orderLoading", payload: false });
            alert(`${responseExport?.displayMessage}`);
            dispatch("clear");
            return;
          }
          currentPageSize = responseExport.result.data.length !== exportPageSize ? 0 : responseExport.result.data.length;

          if (responseExport.result.data.length !== exportPageSize) {
            // if (i === 3) {
            array = array.concat(responseExport.result.data);
            setExportData(array);
            dispatch({ type: "orderLoading", payload: false });
            if (exportt) {
              setExport(false);
              setTimeout(() => {
                setExport(true);
              }, 1500);
            } else {
              setExport(true);
            }
          } else {
            array = array.concat(responseExport.result.data);
          }
        }
      }
    } catch (error) {
      dispatch({ type: "orderConfirmed", payload: false });
      dispatch({ type: "orderLoading", payload: false });
      alert(`Error happened when exporting :${error?.message}`);
      dispatch("clear");
    }
  };

  // Handle jipange export
  const handleJipangeExport = async () => {
    try {
      dispatch({ type: "jipangeDownloadLoading", payload: true });
      const exportPageSize = parseInt(process.env.REACT_APP_EXPORT_PAGESIZE);
      let currentPageSize = parseInt(process.env.REACT_APP_EXPORT_PAGESIZE);
      let array = [];
      for (let i = 0; currentPageSize !== 0; i++) {
        const jipangeFilterBody = {
          pageNumber: i,
          pageSize: exportPageSize,
          filter: {
            customerIds: orderState.jipangeCustomerIds,
            fromDate: orderState.fromDate === null ? dateToUTC(getFromDate()) : dateToUTC(orderState.fromDate),
            toDate: orderState.toDate === null ? dateToUTC(getDefaultEndDate()) : dateToUTC(orderState.toDate)
          }
        };
        const responseExport = await downloadJipangeOrderData(jipangeFilterBody);
        currentPageSize = responseExport.result.data.length !== exportPageSize ? 0 : responseExport.result.data.length;
        if (responseExport?.result.data.length !== exportPageSize) {
          array = array.concat(responseExport.result.data);
          setJipangeExportData(array);
          if (jipangeExport) {
            setJipangeExport(false);

            setTimeout(() => {
              setJipangeExport(true);
            }, 1500);
          } else {
            setJipangeExport(true);
          }
        } else {
          array = array.concat(responseExport.result.data);
        }
      }

      dispatch({ type: "jipangeDownloadLoading", payload: false });
      dispatch({ type: "jipangeFilter", payload: false });
      dispatch("clear");
    } catch (error) {
      alert(`Error happened when exporting :${error?.message}`);
      dispatch("clear");
    }
  };

  const csvReport = {
    data: exportData,
    headers: orderCSV.headers,
    filename: `Orders.csv`
  };

  const csvJipangeReport = {
    data: jipangeExportData,
    headers: jipangeCSV.headers,
    filename: `jipange_orders.csv`
  };

  const Loader = () => {
    const handleClose = () => {
      dispatch({ type: "orderLoading", payload: false });
    };

    return (
      <Dialog open={orderState.orderLoading} onClose={() => handleClose()}>
        <DialogTitle>
          <h2>{orderState.orderConfirmed ? "Exporting..." : "Confirm Export"}</h2>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <h2>Date selected</h2>
            <p>
              <b>FROM: </b>
              {orderState.fromDate ?? getFromDate()}
            </p>
            <p>
              <b>TO: </b>
              {orderState.toDate ?? getDefaultEndDate()}
            </p>

            {orderState.orderConfirmed ? (
              <>
                <CircularProgress color="primary" />
                <p>Please wait as export is preparing...</p>
              </>
            ) : (
              <p>Please confirm dates selected</p>
            )}
          </div>
        </DialogContent>
        {orderState.orderConfirmed ? (
          <></>
        ) : (
          <DialogActions>
            <Button onClick={(e) => handleClose()} variant="outlined" style={addVariantButton}>
              Cancel
            </Button>
            <Button
              style={addProductButton}
              onClick={() => {
                handleExport(true);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Orders </title>
      </Helmet>
      {exportt ? <CSVDownload {...csvReport} /> : <></>}
      {jipangeExport ? <CSVDownload {...csvJipangeReport} /> : null}
      {Loader()}
      <OrderFilter />
      <JipangeOrderFilter handleJipangeExport={handleJipangeExport} />
      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        {/* Header Container */}
        <Grid container spacing={1} sx={{ mt: -4 }} style={headerStyle.headerContainer}>
          <Grid item xs={4} sx={{ mb: orderState.orderDetails ? 3.5 : 1 }}>
            <Typography variant="h4">Orders</Typography>
          </Grid>
          {orderState.orderDetails ? null : (
            <Grid item xs={8} style={headerStyle.searchContainer}>
              <TextField
                onChange={debounce((e) => {
                  e.target.value.length < 3 ? setSearchText(null) : setSearchText(e.target.value);
                }, 300)}
                placeholder="Search Order ID"
                defaultValue={searchText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                      <Search />
                    </InputAdornment>
                  ),
                  style: headerStyle.searchTextField,
                  inputProps: {
                    style: headerStyle.placeHolderText
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
        <Divider />
        {/*Subheader Container */}
        {orderState.orderDetails ? (
          <Grid sx={{ my: 1 }} maxWidth="xxl" spacing={2} gap={2} style={headerStyle.subHeaderArrowContainer}>
            <Grid item xs={6} style={{ display: "flex" }}>
              <Button
                onClick={() => {
                  dispatch({ type: "clear" });
                }}
              >
                <img alt="add" src="/assets/icons/button/back.svg" />
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid sx={{ my: 2 }} maxWidth="xxl" spacing={2} gap={2} style={headerStyle.subHeaderContainer}>
            {isSeller && (
              <Grid item style={{ display: "flex" }}>
                <button
                  className="bg-[#EF7B22] rounded-[8px] text-white px-[16px] py-[8px] font-bold flex gap-[8px] items-center text-[14px]"
                  onClick={() => {
                    // dispatch({ type: "orderFilter", payload: true });
                    navigate(`/dashboard/orders/delivery-request`, { replace: true });
                  }}
                >
                  <p>New Delivery Request</p>
                  <img alt="add" src="/assets/icons/button/add_circle_white.svg" />
                </button>
              </Grid>
            )}
            {!isSeller && (
              <>
                <Grid item xs={6} style={{ display: "flex" }}>
                  <Button
                    style={filterButton}
                    onClick={() => {
                      dispatch({ type: "orderFilter", payload: true });
                    }}
                    endIcon={<img alt="add" src="/assets/icons/button/filter.svg" />}
                  >
                    Filter
                  </Button>
                </Grid>

                <Grid item xs={6} style={{ display: "flex" }}>
                  <Button onClick={() => handleExport(false)} variant="outlined" style={addVariantButton} endIcon={<img alt="add" src="/assets/icons/button/arrow_down_black.svg" />}>
                    Export
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ display: "flex" }}>
                  <Button onClick={() => dispatch({ type: "jipangeFilter", payload: true })} variant="outlined" style={addVariantButton} endIcon={<img alt="add" src="/assets/icons/button/arrow_down_black.svg" />}>
                    Jipange Export
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
        <Grid maxWidth="xxl" sx={{ mt: 2 }}>
          {orderState.orderDetails ? (
            <OrderDetails />
          ) : (
            <>
              <TableContainer>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                      <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={orderTable} style={{ whiteSpace: "nowrap" }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={11} align="center">
                          <CircularProgress sx={{ color: colors.primary }} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      stableSort(orderTable, getComparator(order, orderBy)).map((record) => (
                        <TableRow key={record.orderId}>
                          {/* eslint-disable-next-line */}
                          <TableCell align="left">
                            <p style={pTable}>
                              {" "}
                              <Link to={`/dashboard/orders/details/${record.orderId}${searchText ? `?search=${searchText}` : ""}`}>{record.customerOrderId}</Link>
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.createdAt}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.purchaseType}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.deliveryMode}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.deliveryDate}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.clName === null ? `ID: ${record.clid}` : record.clName}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.customerName === null ? `ID: ${record.customerID}` : record.customerName}</p>
                          </TableCell>{" "}
                          <TableCell align="left">
                            <p style={pTable}>{record.customerType === null ? `ID: ${record.customerID}` : record.customerType}</p>
                          </TableCell>
                          <TableCell align="left">
                            <img src={`/assets/icons/status/${record.paymentStatus}-status.svg`} style={statusButtons} alt={record.paymentStatus} />
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.orderAmount}</p>
                          </TableCell>
                          <TableCell align="left">
                            <img src={`/assets/icons/status/orderStatus/${record.orderStatus}.svg`} style={statusButtons} alt="pending" />
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.deliveryBatchType}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record?.platform ?? ""}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record?.promoCode ?? "NA"}</p>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ height: "20px" }}>
                <TablePagination
                  rowsPerPageOptions={[2, 10, 15, 20, 25]}
                  component="div"
                  count={totalProductsCount}
                  rowsPerPage={pageSize}
                  page={page}
                  onPageChange={(e, newVal) => {
                    updateTableData(pageSize, newVal, searchText);
                  }}
                  onRowsPerPageChange={(e) => {
                    updateTableData(e.target.value, page, searchText);
                  }}
                />
              </div>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
