import { FormControl, TextField } from "@mui/material";
// eslint-disable-next-line
import VTypeFields from "src/components/fields/vType/VTypeFields";
// eslint-disable-next-line
import UnitField from "src/components/fields/units/UnitField";
// eslint-disable-next-line
import BrandField from "src/components/fields/brandFields/BrandField";
import VATTypeFields from "../vatType/VATTypeFields";
import { useContext } from "react";
import DailyLimitField from "../dailyLimitField/dailyLimitField";
import QuantityField from "../quantityField/quantityField";
import { GlobalContext } from "src/context/GlobalState";
import CategoryField from "../categoryField/CategoryField";
import DeliveryBatch from "src/pages/products/components/DeliveryBatch";

const ProductVariantInput = ({ routePath }) => {
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);
  const updateRules = (e) => {
    if (e.target.name === "min") {
      if (productState.productRecord.rules.orderQty === null) {
        const rules = { minimumQty: parseInt(e.target.value), maximumQty: null };
        dispatch({ type: "productRecord", payload: { ...productState?.productRecord, rules: { ...productState?.productRecord.rules, orderQty: rules } } });
      } else {
        dispatch({ type: "productRecord", payload: { ...productState?.productRecord, rules: { ...productState?.productRecord.rules, orderQty: { ...productState?.productRecord.rules.orderQty, minimumQty: parseInt(e.target.value) } } } });
      }
    } else if (e.target.name === "max") {
      if (productState.productRecord.rules.orderQty === null) {
        const rules = {
          minimumQty: null,
          maximumQty: parseInt(e.target.value)
        };
        dispatch({ type: "productRecord", payload: { ...productState?.productRecord, rules: { ...productState?.productRecord.rules, orderQty: rules } } });
      } else {
        dispatch({ type: "productRecord", payload: { ...productState?.productRecord, rules: { ...productState?.productRecord.rules, orderQty: { ...productState?.productRecord.rules.orderQty, maximumQty: parseInt(e.target.value) } } } });
      }
    }

    if (productState.edit) {
      dispatch({ type: "edit", payload: true });
    } else {
      if (productState.addProduct) {
        dispatch({ type: "addProduct", payload: true });
      } else {
        dispatch({ type: "addVariant", payload: true });
      }
    }
  };

  return (
    <FormControl fullWidth>
      <VTypeFields />
      <CategoryField />
      <UnitField routePath={routePath} />
      <QuantityField />
      <DailyLimitField />
      {productState?.productRecord?.mainCategories && productState?.productRecord?.mainCategories[0]?.categoryId === 100 ? (
        <>
          <TextField
            onChange={(e) => {
              updateRules(e);
            }}
            name="min"
            fullWidth
            label="Minimum Quantity"
            type="number"
            variant="standard"
            value={productState?.productRecord.rules?.orderQty?.minimumQty ?? null}
            margin="normal"
          />
          <TextField
            onChange={(e) => {
              updateRules(e);
            }}
            name="max"
            fullWidth
            label="Maximum Quantity"
            type="number"
            variant="standard"
            value={productState?.productRecord.rules?.orderQty?.maximumQty ?? null}
            margin="normal"
          />
        </>
      ) : (
        <></>
      )}
      <BrandField routePath={routePath} />
      <VATTypeFields />
      <DeliveryBatch />
    </FormControl>
  );
};

export default ProductVariantInput;
